// Import polyfills for IE11
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/dom-collections'

import React from 'react'
import ReactDOM from 'react-dom'

import getModules from './modules'

import './modules/common/luna.scss'

export default function hydrate() {
  const targets = document.querySelectorAll('[data-module-name][data-module-scope="tu-global-components"]')

  targets.forEach(target => {
    try {
      const moduleEntry = getModules(target.dataset.moduleName)
      if (!moduleEntry) return

      const props = target.dataset.props ? JSON.parse(target.dataset.props) : null
      ReactDOM.hydrate(React.createElement(moduleEntry.component, props), target)
      // eslint-disable-next-line no-param-reassign
      delete target.dataset.props
    } catch (err) {
      // Unable to hydrate component, silently discarding error
    }
  })
}

hydrate()

if (module.hot) {
  module.hot.accept()
}

import fetch from 'node-fetch'

import logger from '../../../logger'
import { createClickOrigin, appendClickOrigin } from '../../common/utils/clickOrigin'
import { getMeganavDataUrl, isSynapticaEnabled } from '../../../utils/megaNav'
import constants from '../../../constants'

export const MEDIA_URL = 'https://media.4rgos.it/i/Argos'

const CONTENT_COLUMN_TITLE = 'Content block'

// Create a hierarchy id for a nav item, for example `root-0-1-1` or `root-2`
const buildHierarchyId = (...indexes) => `root-${indexes.join('-')}`

export const toSlug = str => (typeof str === 'string' ? encodeURIComponent(str.replace(/\s+/g, '-').toLowerCase()) : '')

const ensureAbsoluteLink = link => {
  if (typeof link !== 'string') return link

  if (link.startsWith('/') || link.startsWith('http')) return link

  return `/${link}`
}

const addLinkTracking = (link, title, categoryTitle, subCategoryTitle) => {
  if (!link) {
    return null
  }

  const clickOrigin = createClickOrigin(
    'header',
    'click',
    'nav',
    toSlug(categoryTitle),
    toSlug(subCategoryTitle),
    toSlug(title)
  )
  return appendClickOrigin(ensureAbsoluteLink(link), clickOrigin)
}

const isContentColumn = column => column[0].title === CONTENT_COLUMN_TITLE

const mapItems = ({ title: columnTitle, links }, columnIndex, topLevelIndex, categoryTitle) => ({
  title: columnTitle,
  link: null,
  id: buildHierarchyId(topLevelIndex, columnIndex),
  items: Array.isArray(links)
    ? links
        .filter(({ title: itemTitle, link: itemLink }) => itemLink && itemTitle)
        .map(({ title: itemTitle, link: itemLink }, itemIndex) => ({
          title: itemTitle,
          link: addLinkTracking(itemLink, itemTitle, categoryTitle, columnTitle),
          id: buildHierarchyId(topLevelIndex, columnIndex, itemIndex),
        }))
    : [],
})

const getContentBlock = (columns, slug) => {
  const contentColumn = columns.find(isContentColumn)
  if (!contentColumn) return null

  return {
    img: `${MEDIA_URL}/${slug}_content?w=318&h=350`,
    alt: contentColumn[0].links[0].title,
    link: ensureAbsoluteLink(contentColumn[0].links[0].link),
  }
}

const fetchJson = async meganavDataUrl => {
  // Create an empty headers object, that will be used by fetch
  const headers = {}

  // If there's a custom user-agent, for example to get past bot protection, set it on the headers
  if (constants.API_USER_AGENT) {
    headers['User-Agent'] = constants.API_USER_AGENT
  }

  // Use Taxonomy API key for Synaptica
  if (constants.ENABLE_SYNAPTICA) {
    // eslint-disable-next-line no-process-env
    headers['x-api-key'] = process.env.TAXONOMY_API_KEY
  }

  const response = await fetch(meganavDataUrl, { headers }).catch(error => {
    // Throw for a fetch specific error
    throw Error(`Error fetching meganav: "${error.message}"`)
  })

  if (!response.ok) {
    // Try to retrieve any error body, but don't fail if missing
    const errorBody = await response
      .text()
      .then(text => `"${text}"`)
      .catch(() => '[Unable to parse response text]')
    // Throw for a fetch response errors
    throw Error(`Error fetching meganav data: "${response.statusText || '[Missing statusText]'}" (${errorBody})`)
  }

  let json
  try {
    json = await response.json()
  } catch (error) {
    // Try to retrieve any error body, but don't fail if missing
    const errorBody = await response
      .text()
      .then(text => `"${text}"`)
      .catch(() => '[Unable to parse response text]')
    // Throw for a JSON deserialising errors
    throw Error(`Error deserialising meganav data: "${error.message}" (${errorBody})`)
  }

  if (!(json && json.body && json.body.data)) {
    // Throw is there's no body, or no body data
    throw Error(`JSON body data is missing, received "${JSON.stringify(json)}"`)
  }

  return json.body.data
}

const getMegaNav = async () => {
  const meganavDataUrl = getMeganavDataUrl()
  const topLevelNavItems = await fetchJson(meganavDataUrl).catch(error => {
    logger.error(error)
    return []
  })
  const useColumns = isSynapticaEnabled()

  const items = topLevelNavItems
    .filter(({ columns, title }) => title && columns[0] && columns[0].length && columns[0][0].links)
    .map(({ columns, link, title }, topLevelIndex) => ({
      title,
      link: addLinkTracking(link, title),
      image: `${MEDIA_URL}/${toSlug(title)}_mobile`,
      textColour: null,
      id: buildHierarchyId(topLevelIndex),
      columns: columns
        .filter(column => !isContentColumn(column))
        .map(column => column.map((item, index) => mapItems(item, index, topLevelIndex, title))),
      items: columns
        .filter(column => !isContentColumn(column))
        .reduce((accum, curr) => accum.concat(curr), [])
        .map((item, index) => mapItems(item, index, topLevelIndex, title)),
      content: getContentBlock(columns, toSlug(title)),
      useColumns,
    }))

  return items
}

export const addShopAllItems = menuItems =>
  menuItems.map(item => {
    const { title, link, id, items } = item
    if (title && link) {
      items.push({ title: `Shop All ${title}`, link, id: `${id}-all` })
    }

    return item
  })

export default getMegaNav

import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import { createClickOrigin } from '../../common/utils/clickOrigin'

const clickOrigin = createClickOrigin('header', 'search', 'auto')

const searchForTerm = async searchTerm => {
  const encodedSearchTerm = encodeURIComponent(searchTerm)
  const response = await fetch(`/suggest?term=${encodedSearchTerm}&fuzziness=true&size=5&channel=tu`)

  if (!response.ok) {
    throw Error(response.statusText)
  }

  const { autoSuggest: { keywords } = {} } = await response.json()

  const results = [
    ...keywords.map(({ value }) => ({
      text: value,
      link: `${getSearchPath(value)}?${clickOrigin}`,
    })),
  ]

  return results
}

export default searchForTerm

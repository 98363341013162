import React from 'react'
import PropTypes from 'prop-types'

import styles from './NavMenu.module.scss'

export default function NavMenu({ children, className, ...props }) {
  return (
    <nav className={`${styles.nav} ${className}`} aria-label="Primary Navigation" {...props}>
      <ul className={styles.list}>
        {React.Children.map(children, (child, index) => (
          <li className={styles.item} data-testid={`Header/NavChild Header/NavChild[${index}]`}>
            {child}
          </li>
        ))}
      </ul>
    </nav>
  )
}

NavMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

NavMenu.defaultProps = {
  children: null,
  className: '',
}

import React from 'react'
import PropTypes from 'prop-types'

import styles from './TaggedText.module.scss'

const TaggedText = ({ children, tagText }) => (
  <div className={styles.container}>
    <span className={styles.text}>{children}</span>
    <span className={styles.tag} role="presentation">
      {tagText}
    </span>
  </div>
)

TaggedText.propTypes = {
  children: PropTypes.node.isRequired,
  tagText: PropTypes.string.isRequired,
}

export default TaggedText

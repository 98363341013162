import React from 'react'
import PropTypes from 'prop-types'
import { subItemShape } from './propTypeShapes'
import styles from './SubNav.module.scss'

export default function ChildItem({ id, title, link, items, onClick, active, setActive, index }) {
  if (!items || !items.length) {
    return (
      <li className={styles.item} style={{ '--i': index }}>
        <a href={link} className={styles.link} data-testid={`SubItemAction/${title}`}>
          {title}
        </a>
      </li>
    )
  }

  const handleClick = () => {
    if (!active.includes(id)) {
      setActive([...active, id])
    }

    if (onClick && typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <li className={styles.item} style={{ '--i': index }}>
      <button type="button" className={styles.link} onClick={handleClick} data-testid={`SubItemAction/${title}`}>
        {title}
      </button>
    </li>
  )
}

ChildItem.propTypes = {
  ...subItemShape,
  active: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

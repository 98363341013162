import { compareDesc, isBefore, isEqual } from 'date-fns'

import constants from '../../../constants'
import schedule from './propBarSchedule'

const getPropBar = async dateNow => {
  if (!constants.ENABLE_PROPBAR) return null

  if (constants.PROPBAR_TEXT && constants.PROPBAR_LINK && constants.PROPBAR_COLOUR) {
    return {
      text: constants.PROPBAR_TEXT,
      link: constants.PROPBAR_LINK,
      icon: constants.PROPBAR_ICON,
      colour: constants.PROPBAR_COLOUR,
    }
  }

  const validPropBars = schedule()
    .filter(a => isEqual(a.from, dateNow) || isBefore(a.from, dateNow))
    .sort((a, b) => compareDesc(a.from, b.from))

  return validPropBars[0] ? validPropBars[0].content : null
}

export default getPropBar

import React, { useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Cancel } from '../../../common/components/Icons'
import useClickAway from '../../../common/utils/clickAway'
import useFocusTrap from '../../../common/utils/focusTrap'
import styles from './HeaderPopup.module.scss'

function renderHeading(id, heading, desc) {
  if (!heading) {
    return null
  }

  let component = heading
  if (typeof heading === 'string') {
    component = (
      <h3 id={`${id}_title`} className={styles.heading} data-testid="heading-text">
        {heading}
      </h3>
    )
  }

  let description = desc
  if (typeof desc === 'string') {
    description = (
      <p id={`${id}_desc`} className={styles.visuallyHidden} data-testid="heading-desc">
        {desc}
      </p>
    )
  }

  return (
    <header className={styles.header} data-testid="heading">
      {component}
      {description}
    </header>
  )
}

function renderChildren(children) {
  if (!children) {
    return null
  }

  return (
    <div test-id="HeaderPopup/Body" className={styles.body}>
      {children}
    </div>
  )
}

function renderFooter(footer) {
  if (!footer) {
    return null
  }

  return (
    <footer className={styles.footer} data-testid="footer">
      {footer}
    </footer>
  )
}

function quickRandomId() {
  return (Math.random() * Date.now()).toFixed(0).substr(0, 8)
}

export default function HeaderPopup({ id, children, className, header, footer, isOpen, onClose, desc, fullHeight }) {
  const wrapperClassNames = [styles.container]

  if (className) {
    wrapperClassNames.push(className)
  }

  /** Animation */
  const content = useRef(null)
  // This state holds the calculated height of the HeaderPopup content.
  const [trueHeight, setTrueHeight] = useState(0)

  useEffect(() => {
    let observer

    // This function calculates the height of HeaderPopup contents, and sets it in state
    const updateTrueHeight = () => {
      if (!content.current) return

      let height

      // Calculate the maximum height of the document or inner window.
      const windowHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      if (fullHeight) {
        // If we're using fullHeight mode, set the true height to the maximum height.
        height = windowHeight
      } else {
        // Otherwise, use the offset height of the header contents element.
        //
        // If the content is taller than the window space remaining below the header then it will overflow the window.
        // As the container height matches the content height, it does not cause it to overflow. The header is also
        // sticky to the top of the window, so on small screens, you could never scroll within the content.
        // To force the content to overflow (and trigger scrolling), we choose the minimum of the two heights.
        //
        // Note: the window height has the desktop height of the header subtracted from it, to account for that used height.
        // As this is a fixed value, it will cause a small pixel gap on really short mobile screens.
        // In reality, screens of this height are very uncommon, and the slightly compressed design is compensated for by
        // maintaining functionality (i.e. not cutting off content).
        height = Math.min(content.current.offsetHeight, windowHeight - 120)
      }

      // Store this value in state.
      setTrueHeight(height)
    }

    if (content.current) {
      // Update the height, incase this is the first time we've mounted the HeaderPopup.
      updateTrueHeight()

      // When the window resizes, recalculate the trueHeight.
      // This ensures that, if the Global Header changes height between different viewports,
      // the HeaderPopup content is resized correctly from the bottom of the header.
      window.addEventListener('resize', updateTrueHeight, false)

      // Create a new Mutation Observer. Whenever the DOM contents of the HeaderPopup content change,
      // this observer will call updateTrueHeight, to recalculate the height of the content.
      observer = new MutationObserver(() => updateTrueHeight())
      observer.observe(content.current, {
        childList: true,
        subtree: true,
      })
    }

    return () => {
      if (observer) {
        // Clean up our observer and window event listener.
        window.removeEventListener('resize', updateTrueHeight, false)
        observer.disconnect()
      }
    }
  }, [
    // Only re-run useEffect when the ref'd content changes.
    content.current,
  ])

  if (isOpen) {
    wrapperClassNames.push(styles['is-open'])
  }

  const style = {
    height: isOpen ? `${trueHeight}px` : 0,
  }

  /** Focus close button on open */
  const closeButton = useRef(null)
  const { preNode, postNode } = useFocusTrap(isOpen, () => content.current)

  /** Close on escape */
  useEffect(() => {
    const onKeyUp = evt => {
      if (evt.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [onClose])

  /** Close on click outside */
  useClickAway(content, isOpen, onClose)

  /** Generate ID if missing */
  const uniqueID = useMemo(() => id || `HeaderPopup-${quickRandomId()}`, [id])

  const aria = {
    'aria-modal': 'true',
  }

  if (header) {
    if (typeof header === 'string' || id) {
      aria['aria-labelledby'] = `${uniqueID}_title`
    }
  }

  if (desc) {
    aria['aria-describedby'] = `${uniqueID}_desc`
  }

  if (!header && !children) {
    return null
  }

  return (
    <aside className={wrapperClassNames.join(' ')} id={uniqueID} role="dialog" {...aria} style={style}>
      {preNode}
      <div ref={content}>
        <div className={styles.closeContainer}>
          <button
            type="button"
            ref={closeButton}
            className={styles.closeButton}
            onClick={onClose}
            data-testid="HeaderPopup/CloseButton"
          >
            <span className={styles.underline}>Close</span>
            <span className={styles.closeIcon}>
              <Cancel />
            </span>
          </button>
        </div>
        {renderHeading(uniqueID, header, desc)}
        {renderChildren(children)}
        {renderFooter(footer)}
      </div>
      {postNode}
    </aside>
  )
}

HeaderPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  desc: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  fullHeight: PropTypes.bool,
}

HeaderPopup.defaultProps = {
  children: undefined,
  className: '',
  header: undefined,
  footer: undefined,
  desc: undefined,
  id: undefined,
  fullHeight: false,
}

import React from 'react'

import toSlug from '../../../common/utils/toSlug'
import sharedStyles from '../../styles/shared.module.scss'
import styles from './Legal.module.scss'
import content from '../../content/legal.json'
import copyright from '../../../common/utils/copyright'

function renderLink({ title, url }) {
  return (
    <a key={toSlug(title)} href={url} className={`${styles.link} ${sharedStyles.link}`}>
      {title}
    </a>
  )
}

export default function Legal() {
  return (
    <div className={styles.wrap}>
      <nav className={styles.menu}>
        <ul>
          {content.links.map(link => (
            <li key={link.id}>{renderLink(link)}</li>
          ))}
        </ul>
      </nav>
      <p className={styles.copyright} data-test="footer-copyright">
        {copyright(content.company)}
      </p>
    </div>
  )
}

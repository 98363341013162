import React from 'react'
import PropTypes from 'prop-types'

import styles from './SearchResults.module.scss'

const SearchResults = ({ searchResults }) =>
  searchResults.length > 0 && (
    <ul className={styles.list} data-testid="SearchResults/Results">
      {searchResults.map(result => (
        <li className={styles.listItem} key={result.link}>
          <a href={result.link} className={styles.result} data-testid="SearchResults/ResultItem">
            {result.text}
          </a>
        </li>
      ))}
    </ul>
  )

SearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SearchResults

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './SubNav.module.scss'
import ChildItem from './ChildItem'
import { subItemShape } from './propTypeShapes'
import { Go } from '../../../common/components/Icons'

export default function SubNav({ id, title, items, active, setActive, onBackClick }) {
  const focusTarget = useRef(null)
  const classNames = [styles.wrapper]

  if (active.includes(id)) {
    classNames.push(styles['is-open'])
    if (active[active.length - 1] !== id) {
      classNames.push(styles['has-open-descendant'])
    }
  }

  /** remember element that was focused before opening */
  /** NOTE: this does not work correctly in Firefox on OS X, as clicking a button does not focus it - this is a known bug */
  const previousElement = useRef(null)
  useEffect(() => {
    if (active[active.length - 1] === id && !previousElement.current && document.activeElement) {
      previousElement.current = document.activeElement
    }
  }, [active])

  function goBack() {
    setActive(active.slice(0, -1))
    if (previousElement.current) {
      previousElement.current.focus()
    }

    if (onBackClick && typeof onBackClick === 'function') {
      onBackClick()
    }
  }

  useEffect(() => {
    if (active[active.length - 1] === id) {
      if (focusTarget.current) {
        focusTarget.current.focus()
      }
    }
  }, [active, focusTarget])

  return (
    <nav className={classNames.join(' ')} data-testid={`SubNav/${title}`}>
      <header className={styles.header}>
        <button type="button" className={styles.close} onClick={goBack} data-testid="subnav-back" ref={focusTarget}>
          <Go className={styles.closeIcon} />
        </button>
        <span className={styles.divider} />
        <p className={styles.heading} data-testid="subnav-title">
          {title}
        </p>
      </header>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <ChildItem {...item} index={index} key={`child-item-${item.id}`} active={active} setActive={setActive} />
        ))}
      </ul>
    </nav>
  )
}

SubNav.propTypes = {
  ...subItemShape,
  active: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActive: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
}

SubNav.defaultProps = {
  onBackClick: undefined,
}

import React from 'react'
import PropTypes from 'prop-types'
import { Cancel, Search as SearchIcon } from '../../../common/components/Icons'

import styles from './SearchBar.module.scss'

const SearchBar = ({ className, name, placeholder, value, onClear, onChange, ...props }) => (
  <div className={`${styles.container} ${className}`}>
    <label htmlFor="searchBarInput" className={styles.visuallyHidden}>
      Search
    </label>
    <div className={styles.formGroup}>
      <input
        id="searchBarInput"
        name={name}
        type="search"
        data-testid="SearchBar/Input"
        data-test="search-input"
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <button
        type="button"
        data-testid="SearchBar/ClearButton"
        className={styles.clearButton}
        aria-label="Clear the field"
        hidden={!value}
        onClick={onClear}
      >
        <Cancel />
      </button>
      <button type="submit" data-testid="SearchBar/SubmitButton" className={styles.submitButton}>
        <SearchIcon className={styles.buttonIcon} />
      </button>
    </div>
  </div>
)

SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
}

SearchBar.defaultProps = {
  value: '',
  placeholder: 'Search product or brand',
  className: '',
}

export default SearchBar

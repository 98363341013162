import React from 'react'
import PropTypes from 'prop-types'
import KlarnaPayLogo from './logos/KlarnaPayLogo'

import styles from './PropBar.module.scss'

const PropBar = ({ text, link, colour, linkText, icon }) => {
  const hasLink = !!link

  const Wrapper = hasLink ? 'a' : 'div'

  const wrapperProps = {
    className: `${styles.propbar} ${styles[colour] || styles.pink}`,
    'data-testid': 'prop-bar',
    href: link || null,
  }

  const propbarLinkText =
    hasLink && linkText ? (
      <>
        &nbsp;<span className={styles.link}>{linkText}</span>
      </>
    ) : null

  const propbarKlarnaImage = icon === 'klarna' ? <KlarnaPayLogo className={styles.propbarSVG} /> : null

  return (
    <Wrapper {...wrapperProps}>
      {propbarKlarnaImage}
      <p className={styles.propbarText}>
        {text}
        {propbarLinkText}
      </p>
    </Wrapper>
  )
}

PropBar.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.oneOf(['klarna', '']),
  colour: PropTypes.oneOf(['pink', 'blue', 'yellow', 'red', 'black', 'blackInverted', 'emergency', 'purple']),
  linkText: PropTypes.string,
}

PropBar.defaultProps = {
  text: "To avoid any non-essential journeys, we've extended our returns policy. For more information, please",
  link: 'https://tuclothing.sainsburys.co.uk/help/returnsAndRefunds',
  icon: '',
  colour: 'pink',
  linkText: 'click here',
}

export default PropBar

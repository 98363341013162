import React from 'react'
import FacebookSVG from '../logos/facebook.svg'
import TikTokSVG from '../logos/tiktok.svg'
import InstagramSVG from '../logos/instagram.svg'
import PinterestSVG from '../logos/pinterest.svg'

export default {
  title: 'Follow us on social media',
  items: [
    {
      icon: <FacebookSVG role="presentation" />,
      title: 'Facebook',
      url: 'https://www.facebook.com/tuclothing',
    },
    {
      icon: <TikTokSVG role="presentation" />,
      title: 'Tiktok',
      url: 'https://www.tiktok.com/@tuclothing',
    },
    {
      icon: <InstagramSVG role="presentation" />,
      title: 'Instagram',
      url: 'https://www.instagram.com/tuclothing',
    },
    {
      icon: <PinterestSVG role="presentation" />,
      title: 'Pinterest',
      url: 'https://www.pinterest.co.uk/tuclothing/',
    },
  ],
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import SearchBar from './SearchBar'
import SearchResults from './SearchResults'
import HeaderPopup from '../HeaderPopup'
import useSearch from '../Search/hooks/useSearch'
import searchForTerm from '../../providers/search'
import { createTrackingEvent } from '../Header/analytics'

import handleSearchSubmit from '../../../../utils/handleSearchSubmit'

// Minimum length of search term before a search will be performed
const MINIMUM_SEARCH_TERM = 3
// Time after last change to search term before a search will be performed
const DEBOUNCE_TIMEOUT = 500

const SearchPopup = ({ isOpen, onClose, className }) => {
  const { searchTerm, searchResults, hasSearched, clearSearch, changeSearchTerm } = useSearch(
    searchForTerm,
    DEBOUNCE_TIMEOUT,
    MINIMUM_SEARCH_TERM
  )

  const handleSearchChange = event => changeSearchTerm(event.target.value)
  const handleSearchClear = (...args) => {
    const sendTrackingEvent = createTrackingEvent('click', { label: 'search:clear' })
    sendTrackingEvent()
    clearSearch(args)
  }

  useEffect(() => {
    const searchPopup = document.querySelector(`.${className}`)
    const { body } = document

    const setBodyOverflow = () => {
      if (searchPopup && window.getComputedStyle(searchPopup).display !== 'none' && isOpen) {
        body.style.overflow = 'hidden'
      } else {
        body.style.overflow = 'auto'
      }
    }

    setBodyOverflow()

    window.addEventListener('resize', setBodyOverflow)

    return () => {
      window.removeEventListener('resize', setBodyOverflow)
    }
  }, [isOpen, className])

  const actionPath = '/search'

  return (
    <div data-testid="SearchPopup">
      <HeaderPopup
        id="search"
        className={className}
        desc="This is the search area."
        isOpen={isOpen}
        onClose={onClose}
        fullHeight
      >
        <form action={actionPath} onSubmit={handleSearchSubmit(searchTerm)}>
          <SearchBar onChange={handleSearchChange} onClear={handleSearchClear} value={searchTerm} />
        </form>
        {hasSearched && <SearchResults searchResults={searchResults} />}
      </HeaderPopup>
    </div>
  )
}

SearchPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
}

SearchPopup.defaultProps = {
  className: '',
}

export default SearchPopup

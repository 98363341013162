import React from 'react'
import PropTypes from 'prop-types'
import { LockedIcon } from '@sainsburys-tech/bolt-icons'
import Logo from '../../../Header/components/Logo'
import HeaderWrap from '../../../Header/components/HeaderWrap'
import getLogoVariant from '../../../Header/providers/logoVariant'

import styles from './HeaderLite.module.scss'

function HeaderLite({ logoVariant }) {
  return (
    <>
      <HeaderWrap className={styles.header}>
        <a href="/" className={styles.logo}>
          <Logo width={40} variant={logoVariant} />
        </a>
        <div className={styles.secureCheckout}>
          <LockedIcon data-test="header-secure-icon" />
          <span>Secure Checkout</span>
        </div>
      </HeaderWrap>
      <div className={styles.placeholder} />
    </>
  )
}

HeaderLite.propTypes = {
  logoVariant: PropTypes.string,
}

HeaderLite.defaultProps = {
  logoVariant: 'default',
}

HeaderLite.getInitialProps = async function getInitialProps() {
  const logoVariant = getLogoVariant(new Date())

  return { logoVariant }
}

export default HeaderLite

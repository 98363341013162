import asIcon from './asIcon'

import AccountSVG from './img/account.svg'
import BagSVG from './img/bag.svg'
import CancelSVG from './img/cancel.svg'
import DeleteSVG from './img/delete.svg'
import DeliverySVG from './img/delivery.svg'
import ReturnsSVG from './img/returns.svg'
import SearchSVG from './img/search.svg'
import StoreSVG from './img/store.svg'
import GoSVG from './img/go.svg'
import TickSVG from './img/tick.svg'

export const Account = asIcon(AccountSVG)
export const Bag = asIcon(BagSVG)
export const Cancel = asIcon(CancelSVG)
export const Delete = asIcon(DeleteSVG)
export const Delivery = asIcon(DeliverySVG)
export const Returns = asIcon(ReturnsSVG)
export const Search = asIcon(SearchSVG)
export const Store = asIcon(StoreSVG)
export const Go = asIcon(GoSVG)
export const Tick = asIcon(TickSVG)

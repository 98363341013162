import createLogger from 'pino'

import constants from './constants'

const logger = createLogger({
  formatters: {
    level: label => ({ level: label }),
  },
  level: constants.LOG_LEVEL,
})

export default logger

import { useState } from 'react'

import signUp from '../../providers/signUp'

const FORM_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SUCCESSFUL: 'SUCCESSFUL',
  ERRORED: 'ERRORED',
}

const useSignupForm = () => {
  const [formState, setFormState] = useState(FORM_STATE.INITIAL)
  const [error, setError] = useState(null)

  const submitForm = async email => {
    if (!email || formState === FORM_STATE.LOADING) {
      return
    }

    setFormState(FORM_STATE.LOADING)
    setError(null)

    try {
      await signUp(email)
      setFormState(FORM_STATE.SUCCESSFUL)
    } catch (err) {
      setFormState(FORM_STATE.ERRORED)
      setError(err.message)
    }
  }

  return {
    isLoading: formState === FORM_STATE.LOADING,
    isSuccessful: formState === FORM_STATE.SUCCESSFUL,
    hasError: formState === FORM_STATE.ERRORED,
    submitForm,
    error,
  }
}

export default useSignupForm

import Footer from './Footer'
import Header from './Header'
import HeaderLite from './HeaderLite'

const modules = {
  header: {
    name: 'Header',
    component: Header,
  },
  headerlite: {
    name: 'HeaderLite',
    component: HeaderLite,
  },
  footer: {
    name: 'Footer',
    component: Footer,
  },
}

function getModules(moduleName) {
  return modules[String(moduleName).toLowerCase()] || null
}

export default getModules

/* eslint-disable no-process-env */

// Dynamic keys to prevent Razzle inlining build time variables
const PORT = 'PORT'

const asInt = string => parseInt(string, 10) || null

export default {
  PORT: process.env[PORT] || 3000,
  LOG_LEVEL: process.env.LOG_LEVEL ? String(process.env.LOG_LEVEL).toLowerCase() : 'info',
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.APP_ENV,
  API_URL: process.env.API_URL || 'http://localhost:3030',
  CIS_REFRESH_ENDPOINT: process.env.CIS_REFRESH_ENDPOINT || 'http://localhost:3000',
  CIS_REFRESH_PATH: process.env.CIS_REFRESH_PATH || '/cis-api/v2/refresh',
  RENDER_CACHE_TIME: asInt(process.env.RENDER_CACHE_TIME),
  RENDER_RETRY_AFTER: asInt(process.env.RENDER_RETRY_AFTER || '10000'),
  RENDER_ERROR_THRESHOLD: asInt(process.env.RENDER_ERROR_THRESHOLD || '50'),
  DISABLE_PREVIEW: process.env.DISABLE_PREVIEW === 'true',
  ENABLE_ERROR_RESPONSES: process.env.ENABLE_ERROR_RESPONSES === 'true',
  DISABLE_LOGO_VARIANT: process.env.DISABLE_LOGO_VARIANT === 'true',
  OVERRIDE_LOGO_VARIANT: process.env.OVERRIDE_LOGO_VARIANT,
  ASSETS_HOST: process.env.ASSETS_HOST,
  // Fixed assets path, to match public path used in Razzle
  ASSETS_PATH: '/assets/global',
  ASSET_QUERY_STRING: process.env.ASSET_QUERY_STRING,
  ENABLE_SYNAPTICA: process.env.ENABLE_SYNAPTICA === 'true',
  ENABLE_STACK_TRACES: process.env.ENABLE_STACK_TRACES === 'true',
  // Prop bar
  ENABLE_PROPBAR: process.env.ENABLE_PROPBAR === 'true',
  PROPBAR_TEXT: process.env.PROPBAR_TEXT,
  PROPBAR_LINK: process.env.PROPBAR_LINK,
  PROPBAR_ICON: process.env.PROPBAR_ICON,
  PROPBAR_COLOUR: process.env.PROPBAR_COLOUR,
  API_USER_AGENT: process.env.API_USER_AGENT,
}

import React, { useMemo, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import useClickAway from '../../../common/utils/clickAway'

import styles from './TouchNavigationDrawer.module.scss'
import { rootItemShape } from './propTypeShapes'
import SubNav from './SubNav'
import RootItem from './RootItem'
import BurgerButton from './BurgerButton'
import useDidUpdateEffect from './hooks/useDidUpdateEffect'

function flattenSubmenus(menu) {
  return menu
    .filter(item => item.items)
    .reduce((accum, curr) => {
      accum.push(curr)
      if (curr.items) {
        accum.push(...curr.items.filter(({ items }) => items && items.length))
      }
      return accum
    }, [])
}

/**
 * This is used to render the background node into a separate part of the DOM via React Portals
 *
 * In order to avoid z-index stacking context issues incurred by the animation, it is necessary
 * to provide the consumer with a method to declare where to render the background node.
 *
 * Furthermore, the UI library is not aware of the context in which it will be consumed. Thus
 * we cannot make sensible assumptions about the consumers z-index and stacking contexts.
 *
 * This provides an easy escape hatch to have absolute control over the z-index positioning of the
 * background, ensuring that only the desired content is obscured.
 *
 * @param {func<Node>} getBackgroundNode - a function that returns a node. The background will be injected into this node.
 * @param {bool} isOpen - is the drawer open
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context|Stacking Contexts Explained}
 * @see {@link https://reactjs.org/docs/portals.html|React Portals Documentation}
 */
function renderBackground(getBackgroundNode, isOpen) {
  const background = <div className={`${styles.background} ${isOpen ? styles['is-active'] : ''}`} />

  const backgroundNode = getBackgroundNode && getBackgroundNode()

  return backgroundNode ? ReactDOM.createPortal(background, backgroundNode) : background
}

export default function TouchNavigationDrawer({
  className,
  items,
  getBackgroundNode,
  onBurgerToggle,
  onBackClick,
  accountModalIsActive,
}) {
  const [isOpen, setOpen] = useState(false)
  const submenuItems = useMemo(() => flattenSubmenus(items), [items])
  const [active, setActive] = useState([])

  const rootNodes = items.map(item => <RootItem {...item} key={item.id} setActive={setActive} />)

  const submenuNodes = submenuItems.map(item => (
    <SubNav {...item} key={`subnav-${item.id}`} active={active} setActive={setActive} onBackClick={onBackClick} />
  ))

  const classNames = [styles.wrapper, className]

  if (isOpen) {
    classNames.push(styles['is-active'])
  }

  function onToggle() {
    setOpen(!isOpen)
    if (isOpen) {
      setActive([])
    }
  }

  const wrapper = useRef(null)
  useClickAway(wrapper, isOpen && !accountModalIsActive, () => {
    setOpen(false)
    setActive([])
  })

  useDidUpdateEffect(() => {
    if (onBurgerToggle && typeof onBurgerToggle === 'function') {
      onBurgerToggle(isOpen)
    }
  }, [isOpen])

  return (
    <>
      {renderBackground(getBackgroundNode, isOpen)}
      <div className={classNames.join(' ')} ref={wrapper} data-testid="TouchNavigationDrawer/Wrapper">
        <BurgerButton isOpen={isOpen} onToggle={onToggle} />
        <nav className={styles.drawer} aria-label="Primary Navigation">
          <ul className={styles.list}>{rootNodes}</ul>
          <div>{submenuNodes}</div>
        </nav>
      </div>
    </>
  )
}

TouchNavigationDrawer.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(rootItemShape)),
  getBackgroundNode: PropTypes.func,
  onBurgerToggle: PropTypes.func,
  onBackClick: PropTypes.func,
  accountModalIsActive: PropTypes.bool,
}

TouchNavigationDrawer.defaultProps = {
  className: '',
  items: [],
  getBackgroundNode: undefined,
  onBurgerToggle: undefined,
  onBackClick: undefined,
  accountModalIsActive: false,
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import analyze from 'rgbaster'

import styles from './TouchNavigationDrawer.module.scss'
import { rootItemShape } from './propTypeShapes'

const getLightnessFromRGB = rgb => {
  // RegEx for rgb(255,255,255)
  const matches = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.exec(rgb)

  // If this isn't a valid match, return that the lightness is white - and use the default black text.
  if (!matches) return 255

  const [, r, g, b] = matches
  // Formula for relative luminance. https://en.wikipedia.org/wiki/Relative_luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

export default function RootItem({
  id,
  title,
  subtitle,
  link,
  image,
  textColour: itemTextColour,
  items,
  onClick,
  setActive,
}) {
  const [textColour, setTextColour] = useState(itemTextColour)
  const classNames = []
  const style = {}

  let text

  if (image) {
    classNames.push(styles.tile)
    style.backgroundImage = `url(${image})`

    if (textColour !== 'dark') {
      classNames.push(styles[textColour])
    }

    // FIXME: useEffect should not be called conditionally here: https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
    /* eslint react-hooks/rules-of-hooks: 'warn'
      -----
      Switching error to warning to allow this to continue working as initially implemented but need to be fixed
    */
    useEffect(() => {
      analyze(image, { scale: 0.2 }).then(results => {
        const dominantColour = results[0].color
        const lightness = getLightnessFromRGB(dominantColour)

        if (lightness < 128) {
          setTextColour('light')
        }
      })
    }, [image])

    text = (
      <div>
        <p className={styles.title} data-testid="root-item-text">
          {title}
        </p>
        {subtitle && (
          <p className={styles.subtitle} data-testid="root-item-subtitle">
            {subtitle}
          </p>
        )}
      </div>
    )
  } else {
    classNames.push(styles.link)
    text = <span data-testid="root-item-text">{title}</span>
  }

  const handleClick = () => {
    setActive([id])

    if (onClick && typeof onClick === 'function') {
      onClick()
    }
  }

  const button = (
    <li className={styles.item}>
      <button
        type="button"
        className={classNames.join(' ')}
        style={image ? style : { background: 'none' }}
        onClick={handleClick}
        data-testid="root-item-action"
      >
        {text}
      </button>
    </li>
  )

  if (!items || !items.length) {
    if (link)
      return (
        <li className={styles.item}>
          <a href={link} className={classNames.join(' ')} style={style} data-testid="root-item-action">
            {text}
          </a>
        </li>
      )

    if (onClick) return button

    return null
  }

  return button
}

RootItem.propTypes = {
  ...rootItemShape,
  setActive: PropTypes.func.isRequired,
}

import React from 'react'
import { PartOfTheFamilyTextLogo, TuLogo, ArgosLogo, HabitatLogo } from '@sainsburys-tech/bolt-icons'

import styles from './PartOfTheFamilyLogo.module.scss'

const PartOfTheFamilyLogo = () => (
  <div className={styles.wrapper}>
    <div className={styles.textLogo}>
      <hr className={styles.divider} />
      <PartOfTheFamilyTextLogo width={100} />
      <hr className={styles.divider} />
    </div>
    <div className={styles.brandLogos}>
      <ArgosLogo key="arg" width={35} height={35} role="img" />
      <TuLogo key="tuc" width={30} height={30} role="img" />
      <HabitatLogo key="hab" width={90} height={20} role="img" />
    </div>
  </div>
)
export default PartOfTheFamilyLogo

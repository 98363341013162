export default () => [
  // 2x Nectar Points
  {
    from: new Date('08 October 2024 00:00:00 UTC'),
    content: {
      text: 'Earn 2x Nectar points shopping with Tu online! For more details',
      link: '/help/nectar?tag=tu:propbar',
      colour: 'purple',
    },
  },
  // base propbar
  {
    from: new Date('07 December 2024 00:00:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  {
    from: new Date('7 November 2024 23:59:00 UTC'),
    content: {
      text: '25% off selected winter warmers. Ends 23:59 on 14th Nov. To shop,',
      link: '/list/shop-tu-winter-warmers?tag=tu:propbar',
      colour: 'black',
    },
  },
  {
    from: new Date('10 November 2024 23:59:00 UTC'),
    content: {
      text: '25% off selected winter warmers & earn 10x Nectar points. Ends 23:59 on 14th Nov.',
      link: '/list/shop-tu-winter-warmers?tag=tu:propbar',
      colour: 'black',
    },
  },
  {
    from: new Date('14 November 2024 23:59:00 UTC'),
    content: {
      text: 'Earn 10x Nectar points when shopping with Tu online. For more details,',
      link: '/help/nectar?tag=tu:propbar',
      colour: 'purple',
    },
  },
  {
    from: new Date('15 November 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  {
    from: new Date('21 November 2024 00:00:00 UTC'),
    content: {
      text: 'Earn 5x Nectar points shopping with Tu online. For more details,',
      link: '/help/nectar?tag=tu:propbar-nectar',
      colour: 'purple',
    },
  },
  {
    from: new Date('22 November 2024 00:00:00 UTC'),
    content: {
      text: '25% off everything & earn 5x Nectar points.  Ends 23:59 29th Nov.  T&C’s apply.  ',
      link: '/list/shop-all-clothing?tag=tu:propbar-bf-nectar',
      colour: 'black',
    },
  },
  {
    from: new Date('27 November 2024 00:00:00 UTC'),
    content: {
      text: '25% off everything.  Ends 23:59 29th Nov.  T&C’s apply.  ',
      link: '/list/shop-all-clothing?tag=tu:propbar-bf',
      colour: 'black',
    },
  },
  {
    from: new Date('30 November 2024 00:00:00 UTC'),
    content: {
      text: 'Up to 50% off sale! To shop, ',
      link: '/events/sale?tag=tu:propbar-sale',
      colour: 'red',
    },
  },
]

/* eslint-disable no-process-env */
import constants from '../constants'

export function isSynapticaEnabled() {
  const { ENABLE_SYNAPTICA } = constants
  const { TAXONOMY_HOST, TAXONOMY_API_KEY } = process.env

  if (ENABLE_SYNAPTICA) {
    if (!TAXONOMY_HOST) throw new Error('Environment variable "TAXONOMY_HOST" is not defined')
    if (!TAXONOMY_API_KEY) throw new Error('Environment variable "TAXONOMY_API_KEY" is not defined')

    return true
  }

  return false
}

export function getMeganavDataUrl() {
  const { API_URL } = constants
  const { TAXONOMY_HOST } = process.env
  let meganavDataUrl

  if (isSynapticaEnabled()) {
    meganavDataUrl = `${TAXONOMY_HOST}/meganav?vocabId=Tu%20Header%20Navigation`
  } else {
    // Fallback to Hybris
    meganavDataUrl = `${API_URL}/navnode/node/v2`
  }

  return meganavDataUrl
}

import React from 'react'
import PropTypes from 'prop-types'

import styles from './HeaderWrap.module.scss'

export default function HeaderWrap({ className, children }) {
  return (
    <header className={`${styles.wrap} ${className}`}>
      <div className={`${styles.wrapInner} ${className}`}>{children}</div>
    </header>
  )
}

HeaderWrap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

HeaderWrap.defaultProps = {
  className: '',
  children: null,
}

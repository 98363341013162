import React from 'react'
import SainsburysSVG from '../logos/sainsburys.svg'
import HabitatSVG from '../logos/habitat.svg'
import ArgosSVG from '../logos/argos.svg'
import NectarSVG from '../logos/nectar.svg'

export default {
  title: 'Our Brands',
  items: [
    {
      icon: <SainsburysSVG role="presentation" />,
      title: "Sainsbury's",
      url: 'https://www.sainsburys.co.uk/',
    },
    {
      icon: <ArgosSVG role="presentation" />,
      title: 'Argos',
      url: 'https://www.argos.co.uk/?cmpid=SB-TU-16',
    },
    {
      icon: <HabitatSVG role="presentation" />,
      title: 'Habitat',
      url:
        'https://www.habitat.co.uk?utm_source=&amp;utm_medium=referral&amp;utm_campaign=TUFooter&amp;_$ja=tsid:77091|cgn:TUFooter',
    },
    {
      icon: <NectarSVG role="presentation" />,
      title: 'Nectar',
      url: 'https://www.nectar.com/',
    },
  ],
}

import constants from '../../../constants'

const rules = [
  {
    variant: 'bhm',
    match: function match(date) {
      return date.getUTCMonth() === 9
    },
  },
  {
    variant: 'pride',
    match: function match(date) {
      return date.getUTCMonth() === 5
    },
  },
]

export default function getLogoVariant(date) {
  if (constants.DISABLE_LOGO_VARIANT) {
    return 'default'
  }

  if (constants.OVERRIDE_LOGO_VARIANT === 'bhm' || constants.OVERRIDE_LOGO_VARIANT === 'pride') {
    return constants.OVERRIDE_LOGO_VARIANT
  }

  const rule = rules.find(({ match }) => match(date))
  return rule ? rule.variant : 'default'
}

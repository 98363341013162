import { createClickOrigin, getPageType } from '../../../common/utils/clickOrigin'
import sendEvent from '../../../common/utils/analytics'
import getCookie from '../../../../utils/getCookie'

// Annotate Logo link with tracking query string
export const LOGO_URL = `/?${createClickOrigin('header', 'click', 'logo')}`

// Annotate Basket and Checkout links with tracking query string
export const BASKET_URL = `/cart?${createClickOrigin('header', 'click', 'miniBasket', 'viewBasket')}`
export const CHECKOUT_URL = `/cart/checkout?${createClickOrigin('header', 'click', 'miniBasket', 'checkout')}`

// Links in the Account Modal
export const SIGN_IN_URL = `/account/login?${createClickOrigin('header', 'click', 'myAccount', 'login')}`
export const REGISTER_URL = `/account/login?${createClickOrigin('header', 'click', 'myAccount', 'register')}`
export const ACCOUNT_HELP_URL = `/help/my-account?${createClickOrigin('header', 'click', 'myAccount', 'accountHelp')}`

// Annotate My Account links with tracking query sttring
export const LOGIN_URL = `/login?${createClickOrigin('header', 'click', 'myAccount', 'loginRegister')}`
export const LOGOUT_URL = `/logout?${createClickOrigin('header', 'click', 'myAccount', 'logout')}`
export const MY_ACCOUNT_URL = `/my-account?${createClickOrigin('header', 'click', 'myAccount', 'accountHome')}`
export const MY_ACCOUNT_ORDERS_URL = `/my-account/orders?${createClickOrigin(
  'header',
  'click',
  'myAccount',
  'viewTrackOrders'
)}`
export const MY_ACCOUNT_UPDATE_PROFILE_URL = `/my-account/update-profile?${createClickOrigin(
  'header',
  'click',
  'myAccount',
  'changeContactDetails'
)}`
export const HELP_URL = `/help?${createClickOrigin('header', 'click', 'myAccount', 'help')}`

export const createTrackingEvent = (action, attributes = {}) => () =>
  sendEvent({
    name: 'header',
    action,
    origin: getPageType(),
    attributes: {
      ...attributes,
    },
  })

export const loadImpressionFlag = () => {
  if (!window.digitalData) {
    window.digitalData = {}
  }

  window.digitalData.globalHeaderIsEnabled = true
}

const userStateMap = {
  GUEST: 'Non-Registered',
  RECOGNISED: 'Recognised',
  LOGGEDIN: 'Registered',
}

export const setUserInfoTracking = userInfo => {
  if (!Array.isArray(window.digitalData.user) || window.digitalData.user.length === 0) {
    window.digitalData.user = [{}]
  }

  if (!userInfo || !userInfo.userState) {
    return
  }

  const loginStatus = userStateMap[userInfo.userState || 'GUEST']

  window.digitalData.user[0].profile = [
    {
      ...window.digitalData.user[0].profile,
      profileInfo: {
        profileID: userInfo.userId,
      },
      attributes: {
        globalView: !getCookie('PostCodeSessionCookie') || getCookie('PostCodeSessionCookie') === '%2C%2C',
        loginStatus,
      },
    },
  ]
}

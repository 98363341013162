import React, { useState } from 'react'
import PropTypes from 'prop-types'

import toSlug from '../../../common/utils/toSlug'
import sharedStyles from '../../styles/shared.module.scss'
import styles from './Menu.module.scss'
import menuContent from '../../content/menu'

const menu = menuContent.map(({ links, ...column }) => ({
  ...column,
  slug: toSlug(column.title),
  links: links.map(link => ({
    ...link,
    slug: toSlug(link.title),
  })),
}))

function Item({ title, url, attributes, ...props }) {
  return (
    <li className={styles.item} {...props}>
      <a href={url} className={`${styles.link} ${sharedStyles.link}`} {...attributes}>
        {title}
      </a>
    </li>
  )
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  attributes: PropTypes.object,
}

Item.defaultProps = {
  attributes: {},
}

function Column({ title, slug, links, isCurrent, setCurrentMenu }) {
  const classNames = [styles.links]
  if (isCurrent) {
    classNames.push(styles.isOpen)
  }
  return (
    <li className={styles.column}>
      <h3 className={styles.heading}>
        <button
          type="button"
          className={sharedStyles.blankButton}
          onClick={() => setCurrentMenu(isCurrent ? null : slug)}
          data-testid={slug}
        >
          {title}
        </button>
      </h3>
      <ul className={classNames.join(' ')}>
        {links.map((link, i) => (
          <Item key={link.slug} {...link} style={{ '--delay': `${i * 0.05}s` }} data-testid={link.slug} />
        ))}
      </ul>
    </li>
  )
}

Column.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentMenu: PropTypes.func.isRequired,
}

export default function Menu() {
  const [currentMenu, setCurrentMenu] = useState(null)
  return (
    <nav className={styles.wrap} aria-label="Footer Menu" data-testid="Footer/Menu">
      <ul className={styles.columns}>
        {menu.map(column => (
          <Column
            key={column.slug}
            {...column}
            isCurrent={currentMenu === column.slug}
            setCurrentMenu={setCurrentMenu}
          />
        ))}
      </ul>
    </nav>
  )
}

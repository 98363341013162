import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import getClickOrigin from './getClickOrigin'

export default searchTerm => event => {
  event.preventDefault()

  const searchTermEntered = searchTerm && searchTerm.trim()
  if (!searchTermEntered) {
    return
  }

  const searchPath = `${getSearchPath(searchTerm)}?clickOrigin=${getClickOrigin(searchTerm)}`
  window.location.href = searchPath
}

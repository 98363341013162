(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("prop-types"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "prop-types"], factory);
	else if(typeof exports === 'object')
		exports["client"] = factory(require("react"), require("react-dom"), require("prop-types"));
	else
		root["Tu"] = root["Tu"] || {}, root["Tu"]["client"] = factory(root["React"], root["ReactDOM"], root["PropTypes"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_cDcd__, __WEBPACK_EXTERNAL_MODULE_faye__, __WEBPACK_EXTERNAL_MODULE_rf6O__) {
return 
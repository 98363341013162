import React, { useState, useEffect } from 'react'

import { EmailIcon } from '@sainsburys-tech/bolt-icons'
import sendEvent from '../../../common/utils/analytics'
import { getPageType } from '../../../common/utils/clickOrigin'
import useSignupForm from './useSignupForm'
import useEmailValidation from './useEmailValidation'
import LoadingIcon from './LoadingIcon'

import styles from './SignupForm.module.scss'

const SignupForm = () => {
  const [email, setEmail] = useState('')
  const [isEmailValid, validateEmail] = useEmailValidation()
  const { submitForm, isSuccessful, isLoading } = useSignupForm()

  const handleBlur = () => validateEmail(email)
  const handleEmailChange = event => setEmail(event.target.value)
  const handleFormSubmit = event => {
    event.preventDefault()

    if (isEmailValid) {
      submitForm(email)
    }
  }

  useEffect(() => {
    if (isSuccessful) {
      sendEvent({
        name: 'footer',
        action: 'click',
        origin: getPageType(),
        attributes: {
          label: 'marketing:signupSuccess',
        },
      })
    }
  }, [isSuccessful])

  const formClassName = [styles.form]

  if (isLoading) {
    formClassName.push(styles.isLoading)
  }
  if (isSuccessful) {
    formClassName.push(styles.isSuccessful)
  }

  return (
    <>
      <form className={formClassName.join(' ')} onSubmit={handleFormSubmit} data-testid="SignupForm" noValidate>
        <div>
          <div className={styles.success} data-testid="SignupForm/Success">
            <EmailIcon height={42} width={42} />

            <h4 className={styles.header}>All Done!</h4>

            <div className={styles.subText}>Your discount code will be delivered straight to your inbox.</div>
          </div>

          <div className={styles.formWrapper}>
            <EmailIcon height={42} width={42} />
            <div className={styles.ctaTextContainer}>
              <label htmlFor="emailAddressInput" data-testid="SignupForm/EmailInputLabel">
                <h4 className={styles.header}>Sign up for 10% off your first order</h4>
              </label>
              <small className={styles.tAndCText}>
                Open to customers not already signed-up to Tu marketing.{' '}
                <a href="/help/terms-and-conditions" className={styles.link}>
                  T&Cs apply
                </a>
                .
              </small>
            </div>

            <form className={styles.form} onSubmit={handleFormSubmit} noValidate>
              <input
                className={styles.input}
                id="emailAddressInput"
                type="email"
                placeholder="Email address"
                name="emailAddressInput"
                data-testid="SignupForm/EmailInput"
                onChange={handleEmailChange}
                onBlur={handleBlur}
              />
              <button
                className={styles.submit}
                data-test="email-offers-button"
                type="submit"
                data-testid="SignupForm/SignupButton"
              >
                <span className={styles.submitClean}>
                  <span className={styles.submitButtonTextWrap}>
                    <span className={styles.submitButtonText}>Submit</span>
                  </span>
                </span>
                <span className={styles.submitLoading}>
                  <LoadingIcon className={styles.loadingIcon} width="50" height="50" data-testid="SignupForm/Loading" />
                </span>
              </button>
            </form>
          </div>
        </div>
      </form>
      {!isEmailValid && !isSuccessful && (
        <div className={styles.error} data-testid="SignupForm/EmailValidationError">
          <span>!</span> Please enter a valid email address
        </div>
      )}
      <small className={styles.legalText}>
        By post, email, text message, telephone and other electronic means. For more information on how we use your
        personal details please refer to our{' '}
        <a className={styles.link} href="https://privacy-hub.sainsburys.co.uk/privacy-policy/">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a className={styles.link} href="https://privacy-hub.sainsburys.co.uk/cookie-policy/">
          Cookie Policy
        </a>
        .
      </small>
    </>
  )
}

export default SignupForm

import React from 'react'
import PropTypes from 'prop-types'

const ICON_SIZE = '20px'

const asIcon = Component => {
  const IconComponent = ({ style, ...props }) => {
    const combinedStyle = {
      // These are reimplemented from `ln-c-icon`, as this classname is not backwards compatible
      display: 'inline-block',
      verticalAlign: 'middle',
      ...style,
      width: ICON_SIZE,
      height: ICON_SIZE,
    }

    return <Component style={combinedStyle} role="img" aria-hidden {...props} />
  }

  IconComponent.propTypes = {
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }

  IconComponent.defaultProps = {
    style: undefined,
  }

  return IconComponent
}

asIcon.propTypes = {
  Component: PropTypes.node,
}

export default asIcon

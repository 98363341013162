function getPageType() {
  let {
    dataset: { pageType },
  } = document.querySelector('#js-global-settings') || { dataset: { pageType: null } }

  if (pageType) {
    if (pageType === 'plp') {
      if (window.location.pathname.includes('search')) {
        pageType = 'slp'
      }
    }

    if (pageType === 'CART') {
      pageType = 'basket'
    }
  } else {
    switch (window.location.pathname) {
      case '':
      case '/':
        pageType = 'home'
        break
      case '/login':
        pageType = 'login'
        break
      case '/checkout':
        pageType = 'checkout'
        break
      case '/login/checkout':
        pageType = 'checkout-login'
        break
      default:
        pageType = 'content'
        break
    }
  }

  return pageType.toLowerCase()
}

function createClickOrigin(...parts) {
  return `clickOrigin=${parts.filter(Boolean).join(':')}`
}

function appendClickOrigin(url = '', clickOrigin) {
  const clickOriginParam = url.includes('?') ? `&${clickOrigin}` : `?${clickOrigin}`
  return `${url}${clickOriginParam}`
}

export { createClickOrigin, appendClickOrigin, getPageType }

import visaImg from '../logos/visa.png'
import mastercardImg from '../logos/mastercard.png'
import amexImg from '../logos/amex.png'
import visaElectronImg from '../logos/visa-electron.png'
import applePayImg from '../logos/applePay.png'
import paypalImg from '../logos/paypal.png'

export default {
  title: 'Accepted payment options',
  items: [
    {
      img: visaImg,
      title: 'Visa',
    },
    {
      img: mastercardImg,
      title: 'Mastercard',
    },
    {
      img: amexImg,
      title: 'American Express',
    },
    {
      img: visaElectronImg,
      title: 'Visa Electron',
    },
    {
      img: applePayImg,
      title: 'Apple Pay',
    },
    {
      img: '//media.4rgos.it/i/Argos/footer-klarna?w=67&h=44&qlt=75',
      title: 'Klarna',
    },
    {
      img: paypalImg,
      title: 'PayPal',
    },
  ],
}

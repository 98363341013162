import { canUseDom } from '@sainsburys-tech/boltui-utils'

export default function getSiteSection() {
  if (
    canUseDom() &&
    global &&
    global.digitalData &&
    global.digitalData.page &&
    global.digitalData.page.pageInfo &&
    global.digitalData.page.pageInfo.siteSection
  ) {
    const { siteSection } = global.digitalData.page.pageInfo
    return siteSection.substring(3)
  }
  return ''
}

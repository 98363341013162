import { useMemo } from 'react'

export default function useComposedStyles(styles, className) {
  const composed = useMemo(
    () =>
      className
        ? Object.keys(styles).reduce(
            (accum, curr) => ({
              ...accum,
              [curr]: `${styles[curr]} ${className}-${curr}`,
            }),
            {}
          )
        : styles,
    [styles, className]
  )

  return name => composed[name] || `${className}-${name}`
}

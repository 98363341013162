import { useEffect, useState } from 'react'

import useDebounce from './useDebounce'

/**
 * Hook to set, get and clear a search, based on a search function that should have throttled
 * calls and a minimum search term length.
 *
 * @param {string} searchFunction The search function to be called with the search term. Should return a Promise that resolves to an array of result objects
 * @param {number} debounceTimeout The time to wait after the last searchTerm update before calling searchFunction
 * @param {number} minimumSearchTerm The minimum number of characters before a searchTerm will be used
 */
function useSearch(searchFunction, debounceTimeout, minimumSearchTerm) {
  // Hold the state of the input `searchTerm`. This is immediately updated.
  const [searchTerm, setSearchTerm] = useState('')
  // Hold the state of `hasSearched`. This is updated internally to the hook.
  const [hasSearched, setHasSearched] = useState(false)
  // Hold the state of the `searchResults`. These are populated by the `searchFunction`.
  const [searchResults, setSearchResults] = useState([])

  // Debounce the `searchTerm`, as set by `changeSearchTerm`. The lifecycle for this is
  // handled by React with hooks. Despite appearing to be a function call setting a value,
  // this can return a different value once the timeout is complete, which React will handle
  // and rerender the hook from.
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout)

  // This is the crucial partner to the debounced search term. The `useEffect` callback will only be called
  // when the `debouncedSearchTerm` changes. As such, it won't happen every render - only when a search term
  // has changed and the debounce timeout has subsequently elapsed.
  useEffect(() => {
    async function doSearch() {
      if (!debouncedSearchTerm || debouncedSearchTerm.length <= minimumSearchTerm) {
        // If there's no search term or it's below the minimum character length, the user has started a new
        // search, and we should hide the existing results.
        setHasSearched(false)
        return
      }

      // Wait for the results from the searchFunction.
      const results = await searchFunction(debouncedSearchTerm)

      // Set the results.
      setSearchResults(results)
      // Set that we've completed a search, so the search results are safe to be shown.
      setHasSearched(true)
    }

    doSearch()
  }, [debouncedSearchTerm])

  // A helper function to clear the search results
  const clearSearch = () => {
    setSearchTerm('')
    setSearchResults([])
    setHasSearched(false)
  }

  return {
    // Used to update the search term - likely as a result of user input
    changeSearchTerm: setSearchTerm,
    // Used to clear the existing results and search term
    clearSearch,
    // The search term as it was used for the search
    searchResultsTerm: debouncedSearchTerm,
    // The search term to be used with controlled inputs
    searchTerm,
    // If a search has been performed
    hasSearched,
    // The search results
    searchResults,
  }
}

export default useSearch

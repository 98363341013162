import { setUserInfoTracking } from '../../Header/components/Header/analytics'

const GET_USER_INFO_PATH = '/webapp/wcs/stores/servlet/GetUserInfo?langId=110&storeId=10151'
const CIS_REFRESH_PATH = '/cis/refresh'

const retrieveUserInfo = async () => {
  try {
    const response = await fetch(GET_USER_INFO_PATH)
    const userInfo = await response.json()
    setUserInfoTracking(userInfo)
  } catch (error) {
    console.error(JSON.stringify(error)) // eslint-disable-line no-console -- Handle and logs errors in the console
  }
}

// Check whether to call the refresh endpoint
const shouldRefreshSession = () => {
  const cisIdCookieName = 'cisId'
  const cisDelayCookieName = 'tu_cis_delay_refresh'

  const cookies = global.document.cookie
  const cisIdExists = cookies.split(';').filter(item => item.trim().startsWith(`${cisIdCookieName}=`)).length
  const cisDelayExists = cookies.split(';').filter(item => item.trim().startsWith(`${cisDelayCookieName}=`)).length
  return !cisIdExists || !cisDelayExists
}

const setCISSession = () => {
  const headers = new Headers({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '-1',
  })

  // Create CIS session cookie if it doesn't exist
  if (shouldRefreshSession()) {
    fetch(CIS_REFRESH_PATH, {
      credentials: 'same-origin',
      method: 'POST',
      headers,
    }).catch(err => {
      console.error(err) // eslint-disable-line no-console -- Handle and logs errors in the console
    })
  }
}

const fetchUserInfo = () => {
  retrieveUserInfo()
  setCISSession()
}

export default fetchUserInfo

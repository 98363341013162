import PropTypes from 'prop-types'

const baseItemShape = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export const subItemShape = {
  ...baseItemShape,
  items: PropTypes.arrayOf(PropTypes.shape(baseItemShape)),
}

export const rootItemShape = {
  ...baseItemShape,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  textColor: PropTypes.oneOf(['dark', 'light']),
  items: PropTypes.arrayOf(PropTypes.shape(subItemShape)),
}

import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import getSiteSection from './getSiteSection'

export default searchTerm => {
  if (!searchTerm) {
    return 'searchbar:search:term'
  }
  const findabilityUrl = getSearchPath(searchTerm)
  const encodedSearchTerm = findabilityUrl
    .substring(8, findabilityUrl.length - 1)
    .replace(/[/]/g, '')
    .replace(/-/g, '+')
    .replace('%2F', '')
  const siteSection = getSiteSection()

  return `searchbar:${siteSection}term:${encodedSearchTerm}`
}

import { useState } from 'react'

const useEmailValidation = (initialState = true) => {
  const [isValid, setIsValid] = useState(initialState)

  const validateEmail = email => {
    const result = /^[\w+-.]+?@[\w-.]+?$/i.test(email)
    setIsValid(result)
  }

  return [isValid, validateEmail]
}

export default useEmailValidation

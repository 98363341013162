import React from 'react'
import PropTypes from 'prop-types'

import toSlug from '../../../common/utils/toSlug'
import useComposedStyles from '../../hooks/useComposedStyles'
import sharedStyles from '../../styles/shared.module.scss'
import styles from './IconMenu.module.scss'

function Item({ title, url, icon, img, styles: itemStyles }) {
  let content
  if (icon) {
    content = icon
  } else if (img) {
    content = <img src={img} alt={title} className={itemStyles.img} width="31" />
  } else {
    content = title
  }
  if (url) {
    return (
      <a href={url} className={itemStyles.link}>
        <span className={sharedStyles.srt}>{title}</span>
        {content}
      </a>
    )
  }
  return content
}

export default function IconMenu({ content, className }) {
  const composedStyles = useComposedStyles(styles, className)
  return (
    <div className={composedStyles('wrap')}>
      <h3 className={sharedStyles.srt}>{content.title}</h3>
      <ul className={composedStyles('list')}>
        {content.items.map(item => (
          <li className={composedStyles('item')} key={toSlug(item.title)}>
            <Item {...item} styles={composedStyles} />
          </li>
        ))}
      </ul>
    </div>
  )
}

IconMenu.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  className: PropTypes.string,
}

IconMenu.defaultProps = {
  className: '',
}

import React from 'react'
import PropTypes from 'prop-types'

import * as logos from './logos'

// Aspect ratio taken from the logo's viewbox. This should not change between seasonal variants of the logo.
const LOGO_ASPECT_RATIO = 5 / 4

const isNumber = value => typeof value === 'number' && Number.isFinite(value)

function Logo({ variant, height, width }) {
  let calculatedHeight
  let calculatedWidth

  if (isNumber(height)) {
    calculatedHeight = height
    calculatedWidth = height * LOGO_ASPECT_RATIO
  } else if (isNumber(width)) {
    calculatedWidth = width
    calculatedHeight = width / LOGO_ASPECT_RATIO
  }

  const LogoSvg = logos[variant]

  return (
    <LogoSvg height={calculatedHeight} width={calculatedWidth} title="Tu Logo" role="img" data-testid="Logo/wrapper" />
  )
}

Logo.propTypes = {
  /**
   * Logo height in pixels, as a number. Width will be set automatically, to preserve aspect ratio.
   * If both height and width are passed, only height will be used.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Logo width in pixels, as a number. Height will be set automatically, to preserve aspect ratio.
   * If both height and width are passed, only height will be used.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['default', 'bhm', 'pride']),
}

Logo.defaultProps = {
  height: undefined,
  width: undefined,
  variant: 'default',
}

export default Logo

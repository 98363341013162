import React from 'react'
import PropTypes from 'prop-types'

import styles from './Badge.module.scss'

const Badge = ({ children, text }) => {
  if (!text) return children

  return (
    <div className={styles.wrapper}>
      <span
        className={`${styles.badge} ${`${text}`.length >= 2 ? styles.multidigit : ''}`}
        data-testid="badge-component"
      >
        {text}
      </span>
      {children}
    </div>
  )
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
}

Badge.defaultProps = {
  text: undefined,
}

export default Badge

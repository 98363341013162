import getPlaceholder from './placeholder'

export const EVENT_LIST_KEY = 'eventList'

function isEventsReady() {
  // Check if the digitalData object and track function are initialised
  const isDigitalDataReady = typeof window.digitalData !== 'undefined' && typeof window.digitalData.track === 'function'

  // If digitalData isn't ready, return false - which will fallback to the analytics placeholder
  if (!isDigitalDataReady) return false

  // Check if the event list is already ready
  const isEventListReady = Array.isArray(window.digitalData[EVENT_LIST_KEY])

  // If it's not, create the eventsList in digitalData
  if (!isEventListReady) {
    window.digitalData.eventList = []
  }

  return true
}

export default function sendEvent(event) {
  if (isEventsReady()) {
    window.digitalData.eventList.push(event)
    window.digitalData.track(event.name)
  } else {
    getPlaceholder(EVENT_LIST_KEY).push(event)
  }
}

import React from 'react'

import styles from './LoadingIcon.module.scss'

const LoadingIcon = ({ ...props }) => (
  <svg className={styles.svg} viewBox="0 0 100 100" data-testid="button-spinner" {...props}>
    <circle className={styles.circle} cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10" />
  </svg>
)

export default LoadingIcon

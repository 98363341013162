import { useState } from 'react'

const BASKET_COUNT_PATH = '/basket-api/v1/basket/items/count'

const useBasketCount = () => {
  const [basketCount, setBasketCount] = useState(0)

  const retrieveBasketCount = () => {
    const headers = new Headers({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '-1',
    })
    fetch(BASKET_COUNT_PATH, {
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
    })
      .then(r => r.json())
      .then(response => {
        const trolleyCount = typeof response === 'string' ? parseInt(response, 10) : response
        setBasketCount(trolleyCount)
      })
      .catch(err => {
        console.error(err) // eslint-disable-line no-console -- Handle and logs errors in the console
      })
  }

  return {
    basketCount,
    retrieveBasketCount,
  }
}

export default useBasketCount

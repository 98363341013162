import { getPageType } from '../../common/utils/clickOrigin/clickOrigin'

const popup = () => {
  // Setup the event array, if it's not already setup.
  // This is different from `eventList` used in other places for analytics.
  window.digitalData.event = window.digitalData.event || []

  // Push the update consent preferences event into the event list.
  window.digitalData.event.push({
    eventInfo: { eventAction: 'Update Consent Preference', eventName: 'updateConsentPreference' },
  })

  // Call track to cause the consent manager window to pop up.
  window.digitalData.track('updateConsentPreference')
}

const popupLegacy = () => {
  // If window.utag.gdpr.showConsentPreferences isn't a function, then we're in Hybris but utag isn't setup right.
  // In case this is because Tealium has been migrated in Hybris, fallback to the modern popup method.
  if (!(window.utag && window.utag.gdpr && typeof window.utag.gdpr.showConsentPreferences === 'function')) {
    popup()
    return
  }

  // Popup the consent preferences modal.
  window.utag.gdpr.showConsentPreferences()

  // Setup the eventList array, if it's not already setup.
  // This is legacy, and different from `event` used in other places for analytics.
  window.digitalData.eventList = window.digitalData.eventList || []

  // Add a tracking event to the eventList.
  window.digitalData.eventList.push({
    name: 'footer',
    action: 'click',
    origin: getPageType(),
    attributes: { label: 'menu:cookiePreferences' },
  })

  // Call track to send the tracking event.
  window.digitalData.track('footer')
}

// In environments rendered by Hybris, this click handler will be overwritten by Hybris' consent manager hook.
// https://github.com/JSainsburyPLC/tu-app/blob/cd747201fe7b375071e75e5ff353857e5da32d49/ext-tu/tustorefront/web/webroot/WEB-INF/_ui-src/2017/scripts/scriptlets/resetTealiumCookiePreferences.js#L4-L9
const showConsentManager = event => {
  // If digitalData is not setup, or track is not a function, then the consent manager will not pop up.
  // As such, we don't want to stop the event propagation, and we just let the link redirect to the privacy hub.
  if (!(window.digitalData && window.digitalData.track && typeof window.digitalData.track === 'function')) {
    throw new Error('Unable to trigger the Tealium consent preferences panel.')
  }

  // Stop the anchor tag's default event, which would redirect to it's href.
  event.preventDefault()

  // If window.ACC.config is defined, then we're in Hybris.
  // While this click handler should have been overwritten, and the code should never reach here, we still need to try to pop up the consent manager just in case.
  if (window.ACC && window.ACC.config) {
    popupLegacy()
    return
  }

  // Popup the consent manager.
  popup()
}

export default showConsentManager

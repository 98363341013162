import React from 'react'
import PropTypes from 'prop-types'

import TaggedText from './TaggedText'
import styles from './MegaMenuList.module.scss'

function MegaMenuItem({ title, link, tag }) {
  return (
    <li>
      <a href={link} className={styles.link} data-testid="MegaMenuList/link">
        {tag ? <TaggedText tagText={tag}>{title}</TaggedText> : title}
      </a>
    </li>
  )
}

MegaMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  tag: PropTypes.string,
}

MegaMenuItem.defaultProps = {
  tag: undefined,
}

export default function MegaMenuList({ title, items }) {
  const style = {
    gridRowEnd: `span ${items.length + 3}`,
  }

  return (
    <section className={styles.wrap} style={style}>
      <p className={styles.heading} data-testid="MegaMenuList/heading">
        {title}
      </p>
      <ul className={styles.list}>
        {items
          .filter(item => !!item.link)
          .map(item => (
            <MegaMenuItem {...item} key={item.id} />
          ))}
      </ul>
    </section>
  )
}

MegaMenuList.defaultProps = {
  items: [],
}

MegaMenuList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      id: PropTypes.string.isRequired,
      tag: PropTypes.string,
    })
  ),
}

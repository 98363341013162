import constants from '../../../constants'

const CLIENT_API_URL = constants.NODE_ENV === 'development' ? constants.API_URL : ''

async function signUp(email) {
  const url = `${CLIENT_API_URL}/global-api/email/address=${email}`

  const methodRequest = 'GET'

  const response = await fetch(url, { method: methodRequest })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export default signUp

import React from 'react'
import PropTypes from 'prop-types'

import { Cancel, Search as SearchIcon } from '../../../common/components/Icons'
import useSearch from './hooks/useSearch'
import searchForTerm from '../../providers/search'
import { createTrackingEvent } from '../Header/analytics'
import SearchResults from './SearchResults'

import handleSearchSubmit from '../../../../utils/handleSearchSubmit'

import styles from './Search.module.scss'

// Minimum length of search term before a search will be performed
const MINIMUM_SEARCH_TERM = 3
// Time after last change to search term before a search will be performed
const DEBOUNCE_TIMEOUT = 500

const Search = ({ className }) => {
  const { searchTerm, searchResults, clearSearch, changeSearchTerm } = useSearch(
    searchForTerm,
    DEBOUNCE_TIMEOUT,
    MINIMUM_SEARCH_TERM
  )

  const handleSearchChange = event => changeSearchTerm(event.target.value)
  const handleSearchClear = (...args) => {
    const sendTrackingEvent = createTrackingEvent('click', { label: 'search:clear' })
    sendTrackingEvent()
    clearSearch(args)
  }

  const actionPath = '/search'

  return (
    <div className={`${styles.container} ${className}`} data-testid="Search">
      <label htmlFor="searchInput" className={styles.visuallyHidden}>
        Search
      </label>
      <form className={styles.formGroup} action={actionPath} onSubmit={handleSearchSubmit(searchTerm)}>
        <SearchIcon className={styles.searchIcon} />
        <input
          id="searchInput"
          type="search"
          data-testid="SearchBar/Input"
          data-test="search-input"
          className={styles.input}
          placeholder="Search product or brand"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          type="button"
          data-testid="SearchBar/ClearButton"
          className={styles.clearButton}
          aria-label="Clear search term"
          title="Clear search term"
          hidden={!searchTerm}
          onClick={handleSearchClear}
        >
          <Cancel />
        </button>
        <button type="submit" data-testid="SearchBar/SubmitButton" className={styles.submitButton}>
          <span className={styles.buttonText}>Search</span>
          <SearchIcon className={styles.buttonIcon} />
        </button>
        <SearchResults searchResults={searchResults} />
      </form>
    </div>
  )
}

Search.propTypes = {
  className: PropTypes.string,
}

Search.defaultProps = {
  className: '',
}

export default Search

import { ANALYTICS_DATA } from './constants'

export default function getPlaceholder(key) {
  if (typeof window[ANALYTICS_DATA] === 'undefined') {
    window[ANALYTICS_DATA] = {}
  }
  if (!Array.isArray(window[ANALYTICS_DATA][key])) {
    window[ANALYTICS_DATA][key] = []
  }
  return window[ANALYTICS_DATA][key]
}

import React from 'react'
import PropTypes from 'prop-types'
import styles from './BurgerButton.module.scss'

export default function BurgerButton({ isOpen, onToggle }) {
  const classNames = [styles.button]

  if (isOpen) {
    classNames.push(styles['is-active'])
  }

  return (
    <button type="button" className={classNames.join(' ')} onClick={onToggle} data-testid="burger-btn">
      <span className={styles.visuallyHidden}>Toggle Open/Close</span>
      <span className={styles.open}>
        <span />
        <span />
        <span />
      </span>
      <span className={styles.close}>
        <span />
        <span />
      </span>
    </button>
  )
}

BurgerButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import MegaMenuList from './MegaMenuList'
import styles from './MegaMenu.module.scss'

function renderList(list) {
  return <MegaMenuList key={`mega-menu-list-${list.id}`} title={list.title} items={list.items} />
}

export default function MegaMenu({ className, contentColumn, items, columns }) {
  // Use the number of columns defined in the props, or fallback to CSS value
  const columnCount = columns && columns.length ? columns.length : undefined

  return (
    <div className={`${styles.wrap} ${className}`}>
      <div className={styles.grid}>
        <div className={styles.menu} style={{ columnCount }}>
          {items && !!items.length && items.map(renderList)}
          {columns &&
            !!columns.length &&
            columns.map((column, index) => <div key={index}>{column.map(renderList)}</div>)}
        </div>
        <aside className={styles.fulfilment}>{contentColumn}</aside>
      </div>
    </div>
  )
}

MegaMenu.defaultProps = {
  className: '',
  contentColumn: null,
  items: [],
  columns: [],
}

MegaMenu.propTypes = {
  className: PropTypes.string,
  contentColumn: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.shape(MegaMenuList.propTypes)),
  columns: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape(MegaMenuList.propTypes))),
}

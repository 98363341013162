/* eslint-disable react/prop-types */
import React from 'react'

import styles from './ErrorBoundary.module.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: JSON.stringify(error) }
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props

    if (hasError) {
      return <span className={styles.visuallyHidden}>{error}</span>
    }

    return children
  }
}

export default ErrorBoundary
